import React, { useEffect, useState } from "react";
import updateHead from "../../utils/helpers/updatePageHead";
import { faCircleDown, faCompass } from "@fortawesome/free-solid-svg-icons";
import { BackgroundGradientLur } from "../../components/Common/BackgroundGradientLur/BackgroundGradientLur";
import GradientButton from "../../components/Common/Buttons/GradientButton";
import { SuggestionCard } from "../../components/Entities/User/SuggestionCard";
import { getUserByUserId } from "../../utils/fetchs/userFetchs";
import Loader from "../../components/Common/Loader/Loader";
import TicketSlider from "../Event/Post/tmpl/TicketSlider";
import { getEventById } from "../../utils/fetchs/eventFetchs";
import { getTicketsFromEventId } from "../../utils/fetchs/ticketFetchs";
import CreaEventCard from "../../components/Entities/Event/CreaEventCard/CreaEventCard";

export default function Presentation() {
  const cssPath = "assets/css/pages/presentation.css";
  const title = "Wive, un réseau social d'événementiel.";

  const [event, setEvent] = useState(null);
  const [tickets, setTickets] = useState(null);
  const [users, setUsers] = useState([]);
  const userIds = [
    "6540d92b1e5a211aeed52bee",
    "6540d92b1e5a211aeed52bec",
    "653189026fc3e182fb0cc01a",
  ];
  const eventId = "6540edc01e5a211aeed52bfc";

  useEffect(() => {
    const fetchEventAndTickets = async () => {
      const fetchedEvent = await getEventById(eventId);
      const fetchedTickets = await getTicketsFromEventId(eventId);
      setEvent(fetchedEvent);
      setTickets(fetchedTickets);
    };

    fetchEventAndTickets();
  }, []);

  useEffect(() => {
    const fetchUsers = async () => {
      const fetchedUsers = [];
      for (let id of userIds) {
        const user = await getUserByUserId(id);
        fetchedUsers.push(user);
      }
      setUsers(fetchedUsers);
    };

    fetchUsers();
  }, []);

  if (!users || !event || !tickets) {
    return <Loader />;
  }

  return (
    <>
      {updateHead({ title, cssPath })}
      <BackgroundGradientLur />
      <section id="project_first_container">
        <h1>Wive, un réseau social d'événementiel.</h1>
        <p className="description">
          Avec Wive, tu ne rates plus aucun événement qui te correspond. Ici, tu
          peux créer, découvrir et rejoindre des événements qui te font vibrer.
          Tu peux y prendre tes billets mais aussi échanger avec d’autres
          utilisateurs. Wive te connecte à tes centres d'intérêts.
        </p>
        <div className="button_wrapper">
          <GradientButton
            icon={faCompass}
            text={"Explorer"}
            padding={"10px"}
            link={"/login-register?feed=true"}
          />
          <GradientButton
            icon={faCircleDown}
            text={"Télécharger l'app"}
            padding={"10px"}
          />
        </div>
      </section>
      <section id="project_second_container">
        <h2>Suis tes créateurs d'événements préférés sur Wive !</h2>
        <div className="hrgradient"></div>
        <div className="wrapper">
          <div className="left_col">
            {users.map((user) => (
              <SuggestionCard key={user.id} user={user} />
            ))}
          </div>
          <div className="right_col">
            <p>
              Sur Wive, tu retrouvera tes créateurs d’événements préférés, et de
              ne rien manquer de leurs actualités en les suivant. Tu peux voir
              leurs événements passés, en cours, ou à venir, et t’y inscrire
              direct sur l'appli. Tu peux aussi leur envoyer des messages,
              partager leurs events, laisser des avis, ou leur faire des
              suggestions. En suivant tes créateurs d’événements préférés, tu
              rejoins une communauté qui te ressemble.
            </p>
          </div>
        </div>
      </section>
      <section id="project_third_container">
        <div className="wrapper">
          <div className="left_col">
            <h2>
              Prends tes billets directement sur l'appli ou le site web 🎫
            </h2>
            <div className="hrgradient"></div>
            <p>
              Une fois que tu as fait ton choix, tu peux payer tes billets en
              ligne, en toute sécurité. Tu reçois alors un QR code unique. Tu
              retrouvera tes billets sur l’appli, ou dans tes mails. Si tu le
              souhaites, tu peux ajouter tes billets à ton wallet, pour les
              avoir toujours à portée de main. Le jour de l’événement, tu n’as
              plus qu’à te présenter à l’entrée, et scanner ton QR code. C’est
              aussi simple que ça ! Et si tu as un imprévu, pas de panique : tu
              peux revendre tes billets directement sur l’appli, à d’autres
              utilisateurs intéressés. Tu peux ainsi récupérer ton argent, et
              faire profiter quelqu’un d’autre de l’événement.
            </p>
          </div>
          <div className="right_col">
            <TicketSlider event={event} tickets={tickets} showTitle={false} />
          </div>
        </div>
      </section>
      <section id="project_fourth_container">
        <h2>
          Crées tes événements privés ou publics et gères tous leurs aspects !
        </h2>
        <div className="hrgradient"></div>
        <p>
          Wive s’adapte à tous types d’événements, que vous vouliez organiser
          une soirée entre amis ou un festival, Wive s'adaptera à vos besoins.
          Vous pourrez créer et publier vos événements facilement, sur le site
          web ou l’appli, et profiter de toutes les fonctionnalités comme les
          conversations d'évents, la billeterie en ligne et l'Event Studio pour
          les gérer efficacement.
        </p>
        <CreaEventCard />
      </section>
    </>
  );
}
