import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import GradientButton from "../../components/Common/Buttons/GradientButton";
import { useUser } from "../../utils/contexts/userContext";
import {
  verifyEmail,
  sendVerificationEmail,
} from "../../utils/fetchs/authFetchs";
import Loader from "../../components/Common/Loader/Loader";
import updateHead from "../../utils/helpers/updatePageHead";
import SingleUserPicture from "../../components/Entities/User/SingleUserPicture";

export default function VerifyEmail() {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useUser();
  const [errorMessage, setErrorMessage] = useState(
    "Vérification de votre email en cours..."
  );
  const [isFetching, setIsFetching] = useState(true);
  const params = new URLSearchParams(location.search);
  const token = params.get("token");
  const cssPath = "assets/css/pages/login_register.css";
  // JE dois faire avec la var token, check si elle est def puis faire les appels ou non en fonction.

  useEffect(() => {
    // Ajout d'un log pour vérifier l'utilisateur au début
    console.log("User at start of useEffect:", user);

    const verifyUserEmail = async () => {
      console.log("Token:", token);
      console.log("User in verifyUserEmail:", user);

      // Attendre que le contexte utilisateur soit initialisé
      if (!user || Object.keys(user).length === 0) {
        setIsFetching(false);
        return;
      }

      if (!token && !user.email) {
        // navigate("/login-register");
        return;
      }

      if (token) {
        try {
          const response = await verifyEmail(token);
          if (response) {
            navigate("/");
          } else {
            setErrorMessage("Échec de la vérification de l'email");
          }
        } catch (error) {
          console.error("Error verifying email:", error);
          setErrorMessage(
            "Une erreur s'est produite lors de la vérification de l'email"
          );
        }
      }
      setIsFetching(false);
    };

    verifyUserEmail();
  }, [user, location, navigate]);

  const handleSendVerificationEmail = async () => {
    if (user && user.email) {
      try {
        const response = await sendVerificationEmail(user.email);
        if (response) {
          setErrorMessage("Un nouvel e-mail de vérification a été envoyé");
        } else {
          setErrorMessage("Erreur lors de l'envoi de l'email de vérification");
        }
      } catch (error) {
        console.error("Error sending verification email:", error);
        setErrorMessage(
          "Une erreur s'est produite lors de l'envoi de l'email de vérification"
        );
      }
    }
  };

  if (isFetching) {
    return <Loader />; // Rendre le composant Loader pendant la vérification
  }

  return (
    <>
      {updateHead({ cssPath })}

      <div className="form_content">
        {user.email ? (
          <>
            <SingleUserPicture user={user} />
            <p className="hello">Bonjour, {user.username} 👋</p>
            <GradientButton
              text="Renvoyer un email"
              onClick={handleSendVerificationEmail}
            />
          </>
        ) : (
          <p>{errorMessage}</p>
        )}
      </div>
    </>
  );
}
