import React, { useState, useEffect, useRef, useContext } from "react";
import { useSpring, animated, config } from "react-spring";
import { Link, useLocation } from "react-router-dom";
import {
  faCompass,
  faCalendar,
  faHome,
  faMap,
  faPlus,
  faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";
import { useUser } from "../../../utils/contexts/userContext";
import { isMobile } from "react-device-detect";
import SearchInput from "./tmpl/SearchInput";
import NavItems from "./tmpl/NavItems";
import GradientButton from "../Buttons/GradientButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getImgUrl } from "../../../utils/contexts/apiContext";
import { ChatboxContext } from "../../../utils/contexts/chatboxContext";
const api_url = getImgUrl();

export default function Navbar() {
  const { user } = useUser(); // Utiliser le contexte utilisateur
  const isLoggedIn = user && user.email;
  const isHomePage = window.location.pathname === "/";
  const location = useLocation();
  const [isChatbox, setIsChatbox] = useState(false);
  const { showNavbar } = useContext(ChatboxContext);
  const [scrollY, setScrollY] = useState(0);
  const navImgRef = useRef(null); // Créez une référence pour l'image
  const [mobileDisplay, setMobileDisplay] = useState(
    isMobile || window.innerWidth < 767.98
  );
  const isSearchPage = location.pathname === "/search";

  /*Handle window reframing */
  useEffect(() => {
    const handleResize = () => {
      setMobileDisplay(isMobile || window.innerWidth < 767.98);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    setIsChatbox(location.pathname == "/chatbox");
  }, [location.pathname]);
  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
      // Ajoutez ou supprimez la classe en fonction de la position de défilement
      if (navImgRef.current && window.scrollY > 50) {
        navImgRef.current.classList.add("scrolled");
      } else if (navImgRef.current) {
        navImgRef.current.classList.remove("scrolled");
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  useEffect(() => {
    const navIcons = document.querySelectorAll(".nav_items .icon");
    if (scrollY > 50) {
      navIcons.forEach((icon) => {
        icon.classList.add("scrolled");
      });
    } else {
      navIcons.forEach((icon) => {
        icon.classList.remove("scrolled");
      });
    }
  }, [scrollY]);

  const navbarHeight = scrollY > 50 ? "80px" : "100px";
  const navbarAnimation = useSpring({
    from: { transform: "translateY(-100%)" },
    to: { transform: "translateY(0%)", height: navbarHeight },
    config: config.slow,
  });
  return (
    <>
      <animated.nav style={navbarAnimation} className="navbar">
        <div className="nav_wrapper">
          <Link to={isLoggedIn ? "/" : "/presentation"}>
            <img
              className="logo_wive"
              src="/assets/images/logo.png"
              alt="Logo Wive"
            />
          </Link>
          {isLoggedIn && (!mobileDisplay || isSearchPage) ? (
            <SearchInput />
          ) : null}
          {isLoggedIn ? (
            <NavItems isChatbox={isChatbox} />
          ) : (
            <GradientButton
              icon={faCompass}
              text={"Explorer"}
              link={"/login-register?feed=true"}
              padding={"10px"}
            />
          )}
        </div>
      </animated.nav>
      {isLoggedIn && mobileDisplay && !(isChatbox && !showNavbar) && (
        <nav className="mobile_navbar">
          <Link to="/events">
            <FontAwesomeIcon icon={faCalendar} className="icon" />
          </Link>
          <Link to="/search">
            <FontAwesomeIcon icon={faMagnifyingGlass} className="icon" />
          </Link>
          {!isHomePage ? (
            <Link to="/">
              <FontAwesomeIcon icon={faHome} className="icon" />
            </Link>
          ) : (
            <GradientButton
              icon={faPlus}
              round={true}
              size={"40px"}
              padding={"10px"}
              link={"/create"}
            />
          )}

          <Link to="/map">
            <FontAwesomeIcon icon={faMap} className="icon" />
          </Link>
          <div className="mobile_user_profile">
            <Link to={`/${user.username}`}>
              <img
                src={`${api_url}/uploads/profilepics/${user.profilepic}`}
                alt="User profile"
              />
            </Link>
          </div>
        </nav>
      )}
    </>
  );
}
