import React, { useState } from "react";
import { ParticipatingFriendList } from "../../../Entities/Event/ParticipatingFriendList";
import { updatePublication } from "../../../../utils/fetchs/publicationFetchs";

export default function SimplifiedEventCard({
  event,
  publication,
  loggedInUser,
}) {
  const [isLiked, setIsLiked] = useState(
    publication.like.includes(loggedInUser._id)
  );

  const handleLike = async () => {
    publication.like.push(loggedInUser._id);
    await updatePublication(
      publication._id,
      publication
    );
    setIsLiked(true);
  };

  const handleDislike = async () => {
    publication.like = publication.like.filter((id) => id !== loggedInUser._id);
    await updatePublication(
      publication._id,
      publication
    );
    setIsLiked(false);
  };

  return (
    <>
      <div className="icons_wrap">
        <ParticipatingFriendList participants={event.participants} />

        <div className="icons_wrap">
          <img
            src={"/assets/images/heart" + (isLiked ? "Filled" : "") + "64px.png"}
            className="post_like"
            alt="like"
            onClick={() => isLiked ? handleDislike() : handleLike()} />
          <p className="like_count">{publication.like.length} j'aimes</p>
        </div>
      </div>
    </>
  );
}
