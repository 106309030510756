import React from "react";
import updateHead from "../../../../utils/helpers/updatePageHead";
import { ChatboxChatHeader } from "../../../Chatbox/Chat/ChatboxChatHeader";
import { ChatboxChat } from "../../../Chatbox/Chat/ChatboxChat";

export default function PostConv({ eventConv, user }) {
  const chatboxPath = "/assets/css/pages/chatbox.css";

  return (
    <>
      {updateHead({ chatboxPath })}
      <ChatboxChatHeader activeConv={eventConv} actualUser={user} />
      <ChatboxChat activeConv={eventConv} actualUser={user} />
    </>
  );
}
