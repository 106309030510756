import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getEventById } from "../../../../utils/fetchs/eventFetchs";
import { getUserByUserId } from "../../../../utils/fetchs/userFetchs";
import { PicturesSlider } from "../tmpl/PicturesSlider";
import { PublicationFooter } from "../tmpl/PublicationFooter";
import SimplifiedEventCard from "../tmpl/SimplifiedEventCard";
import Loader from "../../Loader/Loader";
import PublicationSettings from "../tmpl/PublicationSettings";
import { formatEventTitle } from "../../../../utils/helpers/eventHelper";
import { getImgUrl } from "../../../../utils/contexts/apiContext";
const api_url = getImgUrl();

export function PublicationRepostPictureEvent({ publication, loggedInUser }) {
  const [event, setEvent] = useState(null);
  const [creator, setCreator] = useState(null);

  useEffect(() => {
    getEventById(publication.event_id, loggedInUser).then((event) =>
      setEvent(event)
    );
    getUserByUserId(publication.creator_id).then((user) => setCreator(user));
  }, []);

  if (!event || !creator) {
    return <Loader />;
  }

  const event_title_formatted = formatEventTitle(event);

  return (
    <>
      <li className="post total">
        <div className="info">
          <div className="user_card">
            <Link to={`/${creator.username}`} className="user">
              <div className="profile_picture">
                <img
                  src={`${api_url}/uploads/profilepics/${creator.profilepic}`}
                  alt="profil"
                />
              </div>
            </Link>
            <div>
              <Link to={`/${creator.username}`} className="user">
                <p className="username">@{creator.username}</p>
              </Link>
              <Link to={`/event/${event_title_formatted}`}>
                <div className="info">
                  src="/assets/images/location24px.png" />
                  <p className="event_title">était à {event.event_title}</p>
                </div>
              </Link>
            </div>
          </div>
          <img
            src={`${api_url}/uploads/events/${event.event_picture}`}
            className="post_simplified_image"
            alt="publication"
          />
          <PublicationSettings />
        </div>

        <div className="info repost_info">
          <p className="description">{publication.content_text}</p>
        </div>
        <PicturesSlider publication={publication} />
        <SimplifiedEventCard
          event={event}
          publication={publication}
          creator={creator}
          loggedInUser={loggedInUser}
        />
        <PublicationFooter
          publication={publication}
          event={event}
          loggedInUser={loggedInUser}
        />
      </li>
    </>
  );
}
