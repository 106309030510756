import React, { useEffect, useState } from "react";
import updateHead from "../../../utils/helpers/updatePageHead";
import PostInfos from "./tmpl/PostInfos";
import { translateX30 } from "../../../utils/animations/animations";
import { useSpring, animated } from "react-spring";
import { useLocation, useParams } from "react-router-dom";
import { BackgroundGradientLur } from "../../../components/Common/BackgroundGradientLur/BackgroundGradientLur";
import { getImgUrl } from "../../../utils/contexts/apiContext";
import Loader from "../../../components/Common/Loader/Loader";
import { ChatboxChatHeader } from "../../Chatbox/Chat/ChatboxChatHeader";
import { ChatboxChat } from "../../Chatbox/Chat/ChatboxChat";
import { useUser } from "../../../utils/contexts/userContext";
import { getConversationByEventId } from "../../../utils/fetchs/conversationFetchs";
import { getEventById } from "../../../utils/fetchs/eventFetchs";
import PostConv from "./tmpl/PostConv";
const api_url = getImgUrl();

export default function Post() {
  const { user } = useUser();
  const cssPath = "/assets/css/pages/post.css";

  const animation = translateX30();
  const { eventId } = useParams();
  const [eventConv, setEventConv] = useState(null);
  const [event, setEvent] = useState(null);
  const [creator, setCreator] = useState(null);

  useEffect(() => {
    getEventById(eventId).then((event) => {
      console.log(event);
      setEvent(event);
      setCreator(event.organiser_id);
    });
  }, [eventId]);

  useEffect(() => {
    getConversationByEventId(eventId, user).then((conv) => {
      setEventConv(conv);
    });
  }, [eventId]);

  if (!event || !creator) {
    return <Loader />;
  }
  const title = event.event_title;
  console.log(event, eventConv);
  return (
    <>
      {updateHead({ title, cssPath })}
      <section className="main">
        <BackgroundGradientLur />
        <article>
          <PostInfos event={event} creator={creator} />
          {eventConv && <PostConv eventConv={eventConv} user={user} />}

          <animated.div style={animation} className="post">
            <img
              src={`${api_url}/uploads/events/${event.event_picture}`}
              className="post_image"
              alt="publication"
            />
          </animated.div>
        </article>
      </section>
    </>
  );
}
