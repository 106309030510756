import React from "react";
import { Helmet } from "react-helmet";

export default function updateHead({ title, cssPath }) {
  return (
    <Helmet>
      {title && <title>{title}</title>}
      {cssPath && <link rel="stylesheet" href={cssPath} />}
    </Helmet>
  )
}
