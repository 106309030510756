import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTicket } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { formatEventTitle } from "../../../../utils/helpers/eventHelper";
import GradientButton from "../../../../components/Common/Buttons/GradientButton";

export function TicketSlide({ ticket, event }) {
  const event_title = formatEventTitle(event);

  return (
    <div className="ticket_wrapper">
      <p>{ticket.name}</p>
      <p>{ticket.description}</p>
      <div className="ticket_flex">
        <p className="text_gradient">{ticket.price.toFixed(2)}€</p>
        <Link to={`/event/${event_title}/payements`} state={[event, ticket]}>
          <GradientButton icon={faTicket} round={true} />
        </Link>
      </div>
    </div>
  );
}
