import React, { useEffect, useState } from "react";
import { useSpring, animated } from "react-spring";
import { Portal } from "react-portal";
import updateHead from "../../../utils/helpers/updatePageHead";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import GradientButton from "../../Common/Buttons/GradientButton";
import { Link } from "react-router-dom";
import { getApiUrl } from "../../../utils/contexts/apiContext";
import { getFollowers } from "../../../utils/fetchs/followRequestFetchs";
const api_url = getApiUrl();

export function DisplayFollowers({
  followersList,
  followingList,
  updateUnfollow,
  shouldBeOpen,
  setShouldBeOpen,
}) {
  const [followersUsers, setFollowersUsers] = useState(followersList || []);

  const [followersUsersFiltered, setFollowersUsersFiltered] = useState(
    followersList || []
  );
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const cssPath = "/assets/css/components/display_follow.css"; // Set the CSS to import

  useEffect(() => {
    if (shouldBeOpen) {
      openModal();
    } else {
      closeModal();
    }
  }, [shouldBeOpen]);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setShouldBeOpen(false);
  };

  const handleSearch = (value) => {
    setFollowersUsersFiltered(
      followersUsers.filter((user) => user.username.startsWith(value))
    );
  };

  const modalTransition = useSpring({
    from: { opacity: 0, transform: "scale(0.8) translate(-50%, -50%)" },
    to: {
      opacity: modalIsOpen ? 1 : 0,
      transform: `scale(${modalIsOpen ? 1 : 0.8}) translate(-50%, -50%)`,
    },
    config: { tension: 200, friction: 20 },
  });

  const opacityTransition = useSpring({
    from: { opacity: 0 },
    to: { opacity: modalIsOpen ? 1 : 0 },
  });

  return (
    <>
      {updateHead({ cssPath })}
      {modalIsOpen && (
        <Portal>
          <animated.div
            className="modal_backdrop"
            style={opacityTransition}
            onClick={closeModal}
          ></animated.div>
          <animated.div className="modal_box" style={modalTransition}>
            <div className="modal_follow_title_container">
              <h2>Followers</h2>
            </div>
            <input
              className="follow_search"
              type="text"
              placeholder="Rechercher"
              onChange={(e) => handleSearch(e.target.value)}
            ></input>
            <ul className="modal_follow_list">
              {followersUsersFiltered.map((f, index) => (
                <li key={f._id}>
                  <Link
                    to={`/${f.username}`}
                    className="user_card_container"
                    onClick={closeModal}
                  >
                    <img
                      className="pp_follow"
                      src={`${api_url}/uploads/profilepics/${f.profilepic}`}
                      alt="follower profile"
                    />
                    <p>
                      @{f.username} <br /> <small>&emsp;{f.description}</small>
                    </p>
                  </Link>
                  <div>
                    <GradientButton
                      icon={faTrash}
                      round={true}
                      onClick={() => updateUnfollow()}
                    />
                  </div>
                </li>
              ))}
            </ul>
          </animated.div>
        </Portal>
      )}
    </>
  );
}
