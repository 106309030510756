import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot, faCalendar } from "@fortawesome/free-solid-svg-icons";
import { ParticipatingFriendList } from "../../../Entities/Event/ParticipatingFriendList";
import { updatePublication } from "../../../../utils/fetchs/publicationFetchs";
import dayjs from "dayjs";
import { formatEventTitle } from "../../../../utils/helpers/eventHelper";
import { PublicationFooter } from "./PublicationFooter";
import { getImgUrl } from "../../../../utils/contexts/apiContext";
const api_url = getImgUrl();

export default function DetailedEventCard({
  event,
  publication = null,
  creator,
  loggedInUser,
  detailed = true,
}) {
  const event_title = formatEventTitle(event);
  const [isLiked, setIsLiked] = useState(
    publication.like.includes(loggedInUser._id)
  );

  const handleLike = async () => {
    publication.like.push(loggedInUser._id);
    const savedPublication = await updatePublication(
      publication._id,
      publication
    );
    setIsLiked(true);
  };

  const handleDislike = async () => {
    publication.like = publication.like.filter((id) => id !== loggedInUser._id);
    const savedPublication = await updatePublication(
      publication._id,
      publication
    );
    setIsLiked(false);
  };

  return (
    <>
      <div className="post_event">
        <Link to={`/event/${event._id}`} className="post_image_container">
          <img
            src={`${api_url}/uploads/events/${event.event_picture}`}
            className="post_image"
            alt="publication"
          />
        </Link>
        <div className="post_content">
          <Link to={`/event/${event._id}`}>
            <p className="event_title">{event.event_title}</p>
          </Link>
          <Link to="/map">
            <p className="post_location">
              <FontAwesomeIcon icon={faLocationDot} />{" "}
              {event.event_location_formatted} {/* TODO recup icon🇫🇷*/}
            </p>
          </Link>
          {detailed ? (
            <div className="icons_wrap space_right">
              <ParticipatingFriendList participants={event.participants} />
              <img
                src={
                  "/assets/images/heart" +
                  (isLiked ? "Filled" : "") +
                  "64px.png"
                }
                className="post_like"
                alt="like"
                onClick={() => (isLiked ? handleDislike() : handleLike())}
              />
            </div>
          ) : null}

          <div className="icons_wrap">
            <p className="event_date">
              <FontAwesomeIcon icon={faCalendar} /> Le{" "}
              {dayjs(event.event_date).format("DD/MM/YYYY")} à{" "}
              {dayjs(event.event_date).format("HH:mm")}
            </p>
            {publication ? (
              <p className="like_count">{publication.like.length} j'aimes</p>
            ) : null}
          </div>
          {detailed ? (
            <PublicationFooter
              publication={publication}
              event={event}
              loggedInUser={loggedInUser}
            />
          ) : null}
        </div>
      </div>
    </>
  );
}
