import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationDot,
  faCalendar,
  faHeart,
  faUsers,
  faCheck,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { ParticipatingFriendList } from "../../../../components/Entities/Event/ParticipatingFriendList";
import GradientButton from "../../../../components/Common/Buttons/GradientButton";
import { getImgUrl } from "../../../../utils/contexts/apiContext";
import {
  likeContent,
  unlikeContent,
  getLikes,
} from "../../../../utils/fetchs/likeFetchs";
import { useUser } from "../../../../utils/contexts/userContext";
import { updateEvent } from "../../../../utils/fetchs/eventFetchs"; // Assuming this is where the update function is defined

const api_url = getImgUrl();

export default function PostDetails({ event, creator }) {
  const { user } = useUser();
  const [likesCount, setLikesCount] = useState(0);
  const [isLiked, setIsLiked] = useState(false);

  useEffect(() => {
    const fetchLikes = async () => {
      try {
        const likes = await getLikes(event._id, "Event");
        setLikesCount(likes.length);
        setIsLiked(likes.some((like) => like.user === user._id));
      } catch (error) {
        console.error("Failed to fetch likes", error);
      }
    };

    fetchLikes();
  }, [event._id, user._id]);

  const handleLikeClick = async () => {
    try {
      if (isLiked) {
        await unlikeContent(event._id, "Event", user);
        setLikesCount((prevCount) => prevCount - 1);
      } else {
        await likeContent(event._id, "Event", user);
        setLikesCount((prevCount) => prevCount + 1);
      }
      setIsLiked(!isLiked);
    } catch (error) {
      console.error("Failed to toggle like", error);
    }
  };

  const handleAcceptInvitation = async () => {
    try {
      const updatedEvent = {
        ...event,
        participants: [...event.participants, user._id],
        guest_list: event.guest_list.filter((guestId) => guestId !== user._id),
      };

      await updateEvent(event._id, updatedEvent, user);
      // Optionally, you can update the local state as well
      event.participants.push(user._id);
      event.guest_list = event.guest_list.filter(
        (guestId) => guestId !== user._id
      );
      console.log(event);
    } catch (error) {
      console.error("Failed to accept invitation", error);
    }
  };

  const handleDeclineInvitation = async () => {
    try {
      const updatedEvent = {
        ...event,
        guest_list: event.guest_list.filter((guestId) => guestId !== user._id),
      };

      await updateEvent(event._id, updatedEvent, user);
      // Optionally, you can update the local state as well
      event.guest_list = event.guest_list.filter(
        (guestId) => guestId !== user._id
      );
    } catch (error) {
      console.error("Failed to decline invitation", error);
    }
  };

  return (
    <>
      <div className="user">
        <div className="profile_picture">
          <img
            src={`${api_url}/uploads/profilepics/${creator.profilepic}`}
            alt="profil"
          />
        </div>
        <Link to={`/${creator.username}`}>
          <p className="username">@{creator.username}</p>
        </Link>
      </div>
      <div className="ticket_flex">
        <div className="ticket_flex" style={{ margin: "0px" }}>
          <FontAwesomeIcon icon={faLocationDot} className="icon" />
          <p className="location">{event.event_location_formatted}</p>
        </div>
        <GradientButton
          icon={faHeart}
          textfirst={isLiked}
          text={isLiked ? "Vous aimez" : "Intéressé.e"}
          onClick={handleLikeClick}
        />
      </div>
      <div className="ticket_flex">
        <p className="post_time">
          <FontAwesomeIcon icon={faCalendar} />{" "}
          {dayjs(event.event_date).format("DD/MM/YYYY")}
        </p>
        <p className="like-count">{likesCount} j'aimes</p>
      </div>
      <div className="ticket_flex">
        <div className="ticket_flex" style={{ margin: "0px" }}>
          <FontAwesomeIcon icon={faUsers} className="icon" />
          <p className="people_count" style={{ marginLeft: "5px" }}>
            {event.participants.length} participants
          </p>
        </div>
        <ParticipatingFriendList participants={event.participants} />
      </div>
      {event.guest_list && event.guest_list.includes(user._id) && (
        <div className="ticket_flex">
          <GradientButton
            text="Je viens !"
            icon={faCheck}
            onClick={handleAcceptInvitation}
          />
          <div className="cancel_btn" onClick={handleDeclineInvitation}>
            <FontAwesomeIcon icon={faXmark} />
            <p>Pas là.</p>
          </div>
        </div>
      )}
    </>
  );
}
