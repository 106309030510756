import React, { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { animated } from "react-spring";
import { translateYMinus100 } from "../../../utils/animations/animations";
import Loader from "../../../components/Common/Loader/Loader";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { generateConversationTitle } from "../../../utils/helpers/conversationHelper";
import SingleUserPicture from "../../../components/Entities/User/SingleUserPicture";
import MultipleUserPictures from "../../../components/Entities/User/MultipleUserPictures";
import { ChatboxContext } from "../../../utils/contexts/chatboxContext";
import { getEventById } from "../../../utils/fetchs/eventFetchs";
import { formatEventTitle } from "../../../utils/helpers/eventHelper";

export function ChatboxChatHeader({
  activeConv,
  actualUser,
  showThirdSection,
  setShowThirdSection,
  showChat,
  setShowChat,
}) {
  const [participants, setParticipants] = useState([]);
  const { showConvFirst, setShowConvFirst } = useContext(ChatboxContext);
  const animation = translateYMinus100();
  const [mobileDisplay, setMobileDisplay] = useState(
    isMobile || window.innerWidth < 767.98
  );
  useEffect(() => {
    if (activeConv && activeConv.participants) {
      setParticipants(activeConv.participants);
    }
  }, [activeConv]);

  const handleArrowClick = () => {
    setShowChat(!showChat);
    if (showConvFirst) {
      setShowConvFirst(false);
    }
  };
  const convType = activeConv.type;
  const otherParticipant =
    convType === "private"
      ? participants.find((p) => p._id !== actualUser._id)
      : null;

  if (!participants || participants.length === 0) {
    return <Loader />;
  }

  return (
    <>
      <animated.div style={animation} className="chatbox_right_header">
        <div className="chatbox_flex">
          {mobileDisplay && showChat ? (
            <FontAwesomeIcon icon={faArrowLeft} onClick={handleArrowClick} />
          ) : null}
          {convType === "private" && (
            <Link to={`/${otherParticipant.username}`}>
              <div className="chatbox_header_container">
                <div className="chatbox_user_pp">
                  <SingleUserPicture user={otherParticipant} />
                </div>
                <h2>
                  @{otherParticipant.username}
                  <br />
                  <span>Connecté(e)</span>
                </h2>
              </div>
            </Link>
          )}
          {convType === "group" && (
            <div className="chatbox_header_container">
              <ul className="chatbox_header_friends_list">
                <MultipleUserPictures
                  users={participants.filter((p, i) => i < 3)}
                />
              </ul>

              <h2>
                {generateConversationTitle(activeConv, actualUser)}
                <br />
                {participants.length > 3 && (
                  <span>
                    et {participants.length - 3} autres y participent.
                  </span>
                )}
              </h2>
            </div>
          )}
          {convType === "event" && (
            <div className="chatbox_header_container">{activeConv.title}</div>
          )}
        </div>
        <FontAwesomeIcon
          icon={faCircleInfo}
          onClick={() => setShowThirdSection(!showThirdSection)}
        />
      </animated.div>
      {convType === "event" && <div className="chatbox_header_event"></div>}
    </>
  );
}
