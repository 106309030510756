import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore from "swiper/core";
import { Navigation, Pagination } from "swiper/modules";
import { PictureSlide } from "./PictureSlide";
import "swiper/swiper-bundle.css";

// Installer les modules Swiper
SwiperCore.use([Navigation, Pagination]);

export function PicturesSlider({publication}) {

  return (
    <div className="picture_slider">
        <Swiper
          spaceBetween={30}
          slidesPerView={1}
          navigation
          pagination={{ clickable: true }}
          mousewheel={true}
          >
            
          {publication.content_picture.map(pict => (
            <SwiperSlide key={pict}>
              <PictureSlide  picture={pict} />
            </SwiperSlide>
          ))}
        </Swiper>
    </div>
  );
}
