import React from "react";
import { getImgUrl } from "../../../../utils/contexts/apiContext";
const api_url = getImgUrl();

export function PictureSlide({ picture }) {
  return (
    <div className="publi_picture_container">
      <img
        src={`${api_url}/uploads/events/${picture}`}
        className="publi_picture"
        alt="publication"
      />
    </div>
  );
}
