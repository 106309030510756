import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore from "swiper/core";
import { Navigation, Pagination } from "swiper/modules";
import { TicketSlide } from "./TicketSlide";
import "swiper/swiper-bundle.css";
import updateHead from "../../../../utils/helpers/updatePageHead";
SwiperCore.use([Navigation, Pagination]);

export default function TicketSlider({ event, tickets, showTitle = true }) {
  const cssPath = "/assets/css/components/ticket_slider.css";
  return (
    <>
      {updateHead({ cssPath })}
      <div className="ticket_store">
        {showTitle ? (
          <div className="hrwrapper">
            <p>Billets :</p>
            <div className="hrgradient"></div>
          </div>
        ) : null}

        {tickets.length ? (
          <Swiper
            spaceBetween={30}
            slidesPerView={1}
            navigation
            pagination={{ clickable: true }}
            mousewheel={true}
          >
            {tickets.map((ticket) => (
              <SwiperSlide key={ticket._id}>
                <TicketSlide ticket={ticket} event={event} />
              </SwiperSlide>
            ))}
          </Swiper>
        ) : (
          <p>Il n'y a pas encore de billets disponible pour cet événement</p>
        )}
      </div>
    </>
  );
}
