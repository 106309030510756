import React, { useState, useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faPen,
  faImage,
  faTrash,
  faEllipsisVertical,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { useUser } from "../../../utils/contexts/userContext";
import {
  createPublication,
  deletePublication,
  updatePublication,
} from "../../../utils/fetchs/publicationFetchs";
import { getPublications } from "../../../utils/fetchs/eventFetchs";
import GradientButton from "../../../components/Common/Buttons/GradientButton";
import { getImgUrl } from "../../../utils/contexts/apiContext";
const api_url = getImgUrl();

export default function PublicationCol({ event }) {
  const { user } = useUser();
  const [imagePreview, setImagePreview] = useState(null);
  const fileInputRef = useRef();

  const [publications, setPublications] = useState([
    {
      _id: "",
      creator_id: user._id,
      event_id: event._id,
      publication_date: "",
      content_text: "",
      content_picture: "",
      type: "text_devent",
      like: [],
      repost: [],
      isEditing: true,
    },
  ]);

  useEffect(() => {
    if (event) {
      getPublications(event._id).then((publications) =>
        setPublications(publications)
      );
      addPublication();
    }
  }, [event]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    setImagePreview(URL.createObjectURL(file));
    const updatedPublications = [...publications];
    updatedPublications[0] = {
      ...updatedPublications[0],
      content_picture: file,
    };
    setPublications(updatedPublications);
  };

  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  const handleEditPublication = (index) => {
    const newPublications = [...publications];
    newPublications[index].isEditing = true;
    setPublications(newPublications);
  };

  const updatePublications = (index, field, value) => {
    const newPublications = [...publications];
    newPublications[index][field] = value;
    setPublications(newPublications);
  };

  const addPublication = () => {
    setPublications([
      ...publications,
      {
        _id: "",
        creator_id: user._id,
        event_id: event._id,
        publication_date: "",
        content_text: "",
        content_picture: "",
        type: "",
        like: [],
        repost: [],
        isEditing: true,
      },
    ]);
  };

  const dropPublication = async (index) => {
    const res = await deletePublication(publications[index]);
    const newPublications = publications.filter((_, i) => i !== index);
    setPublications(newPublications);
  };

  const savePublication = async (index) => {
    const newPublications = [...publications];
    newPublications[index].isEditing = false;
    if (index === 1) {
      newPublications[index].type = "event_release";
    } else if (
      newPublications[index].content_picture &&
      newPublications[index].content_text
    ) {
      newPublications[index].type = "pictures_text_sevent";
    } else if (newPublications[index].content_picture) {
      newPublications[index].type = "pictures_sevent";
    } else if (newPublications[index].content_text) {
      newPublications[index].type = "text_devent";
    }

    newPublications[index] =
      publications[index]._id === ""
        ? await createPublication(publications[index])
        : await updatePublication(publications[index]._id, publications[index]);
    setPublications(newPublications);
  };

  return (
    <ul className="publication_col">
      <h2 className="text_gradient second">Publications</h2>

      {publications.map((publication, index) =>
        publication.isEditing ? (
          <li key={index} className="post">
            <div className="info">
              <Link to="/profile" className="user">
                <div className="profile_picture">
                  <img
                    src={`${api_url}/uploads/profilepics/${user.profilepic}`}
                    alt="User profile"
                  />
                </div>
                <p className="username">@{user.username}</p>
              </Link>
              <FontAwesomeIcon icon={faEllipsisVertical} />
            </div>
            <div>
              <label>
                Date et heure de publication :
                <input
                  type="datetime-local"
                  value={publication.publication_date.slice(0, -1)}
                  onChange={(e) =>
                    updatePublications(
                      index,
                      "publication_date",
                      e.target.value
                    )
                  }
                />
              </label>
              <div className="image_container">
                {imagePreview ? (
                  <img
                    src={imagePreview}
                    alt="Publication Thumbnail"
                    className="event_picture"
                    onClick={triggerFileInput}
                  />
                ) : (
                  <button onClick={triggerFileInput}>
                    <FontAwesomeIcon icon={faImage} /> Ajouter une image
                  </button>
                )}
                <input
                  type="file"
                  accept="image/*"
                  ref={fileInputRef}
                  onChange={handleImageChange}
                  style={{ display: "none" }}
                />
              </div>
              <label>
                Contenu :
                <textarea
                  value={publication.content_text}
                  onChange={(e) =>
                    updatePublications(index, "content_text", e.target.value)
                  }
                />
              </label>
              <div className="ticket_flex sm">
                <GradientButton
                  type={"validate"}
                  onClick={() => savePublication(index)}
                />
                <GradientButton
                  type={"delete"}
                  onClick={() => dropPublication(index)}
                />
              </div>
            </div>
          </li>
        ) : (
          <li key={index} className="post">
            <div className="info">
              <Link to="/profile" className="user">
                <div className="profile_picture">
                  <img
                    src={`${api_url}/uploads/profilepics/${user.profilepic}`}
                    alt="User profile"
                  />
                </div>
                <p className="username">@{user.username}</p>
              </Link>
              <FontAwesomeIcon icon={faEllipsisVertical} />
            </div>
            <div>
              <p>{publication.content_text}</p>
              <div className="image_container">
                {publication.content_picture && (
                  <img
                    src={`${api_url}/uploads/events/${publication.content_picture}`}
                    alt="Publication Thumbnail"
                    className="event_picture"
                  />
                )}
              </div>
              <div className="ticket_flex sm">
                <div className="gradient_btn_outline save">
                  <div className="gradient_btn">
                    <button
                      className="ticket_btn"
                      onClick={() => handleEditPublication(index)}
                    >
                      <FontAwesomeIcon icon={faPen} />
                    </button>
                  </div>
                </div>
                <div className="gradient_btn_outline delete">
                  <div className="gradient_btn">
                    <FontAwesomeIcon icon={faTrash} className="icon" />
                    <button onClick={() => dropPublication(index)}>
                      {" "}
                      Supprimer
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </li>
        )
      )}

      <GradientButton
        icon={faPlus}
        text={"Ajouter une publication"}
        padding={"10px"}
        onClick={addPublication}
      />
    </ul>
  );
}
