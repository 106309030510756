import { useEffect, useState } from "react";
import { useSpring, animated } from "react-spring";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faCheck,
  faPlus,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";
import { translateYMinus100 } from "../../../utils/animations/animations";
import {
  createConversation,
  deleteConversation,
  getUserConversations,
  updateConversation,
} from "../../../utils/fetchs/conversationFetchs";
import Loader from "../../../components/Common/Loader/Loader";
import GradientButton from "../../../components/Common/Buttons/GradientButton";
import { getFollowings } from "../../../utils/fetchs/followRequestFetchs";

export default function ConversationEditing({ event, user }) {
  const [friends, setFriends] = useState(null);
  const [searchFriends, setSearchFriends] = useState(null);
  const [conversation, setConversation] = useState({
    _id: "",
    type: "publicEvent",
    participants: [...event.participants, user._id],
    event_id: event._id,
    title: "",
    authorizedSenders: [],
    lastUpdated: null,
  });

  const [authorizedSenders, setAuthorizedSenders] = useState(
    conversation.authorizedSenders
  );

  const handleSearch = (username) => {
    username === ""
      ? setSearchFriends(null)
      : setSearchFriends(
          friends.filter((f) => f.username.startsWith(username))
        );
  };

  useEffect(() => {
    if (user._id) {
      getFollowings(user._id).then((friends) => setFriends(friends));
      if (event.conversation) {
        getUserConversations(user).then((conversation) =>
          setConversation(conversation)
        );
        //TODO fetch authorizedSenders
      }
    }
  }, [user._id]);

  const saveConversation = async () => {
    conversation.lastUpdated = Date.now();
    setConversation(conversation);
    if (!conversation._id) {
      addAuthorizedSender(user._id);
      createConversation(user, conversation).then((conversation) =>
        setConversation(conversation)
      );
    } else {
      updateConversation(user, conversation).then((conversation) =>
        setConversation(conversation)
      );
    }
  };

  const dropConversation = async () => {
    if (conversation._id) {
      await deleteConversation(user, conversation);
    }

    setConversation({
      _id: "",
      type: "publicEvent",
      participants: [...event.participants, user._id],
      event_id: event._id,
      title: "",
      authorizedSenders: [],
      lastUpdated: null,
    });
  };

  const addAuthorizedSender = (senderId) => {
    conversation.authorizedSenders.push(senderId);
    setConversation(conversation);
    setAuthorizedSenders(conversation.authorizedSenders);
    if (senderId !== user._id) {
      setSearchFriends(searchFriends.filter((f) => f._id !== senderId));
    }
  };

  const removeAuthorizedSender = (senderId) => {
    conversation.authorizedSenders = conversation.authorizedSenders.filter(
      (id) => id !== senderId
    );
    setConversation(conversation);
    setAuthorizedSenders(conversation.authorizedSenders);
    searchFriends.push(friends.find((f) => f._id === senderId));
    setSearchFriends(searchFriends);
  };

  const animation = translateYMinus100();

  if (!friends) {
    return <Loader />;
  }

  return (
    <>
      <div className="conversation_col">
        <h2 className="text_gradient second">Conversation</h2>
        <label>Titre</label>
        <input
          type="text"
          defaultValue={conversation.title}
          placeholder="Titre"
          onChange={(e) =>
            setConversation((prevData) => ({
              ...prevData,
              title: e.target.value,
            }))
          }
        />
        <p>
          Ajoutez des personnes ayant le droit d'envoyer des message dans cette
          conversation :{" "}
        </p>

        <animated.div style={animation} className="chatbox_search_chat">
          <input
            type="text"
            placeholder="Rechercher un utilisateur"
            onChange={(e) => handleSearch(e.target.value)}
          />
          {searchFriends && (
            <ul>
              {searchFriends.map((f) => (
                <li key={f._id}>
                  <p>{f.username}</p>
                  <FontAwesomeIcon
                    icon={faPlus}
                    onClick={() => addAuthorizedSender(f._id)}
                  />
                </li>
              ))}
            </ul>
          )}
        </animated.div>

        {authorizedSenders.map(
          (senderId) =>
            senderId !== user._id && (
              <div key={senderId}>
                <p>{friends.find((f) => f._id === senderId).username}</p>
                <FontAwesomeIcon
                  icon={faCircleXmark}
                  onClick={() => removeAuthorizedSender(senderId)}
                />
              </div>
            )
        )}
        <div className="ticket_flex sm">
          <GradientButton
            type={"validate"}
            onClick={() => saveConversation()}
          />
          <GradientButton type={"delete"} onClick={() => dropConversation()} />
        </div>
      </div>
    </>
  );
}
