import { animated } from "react-spring";
import { displayEventDate } from "../../../utils/helpers/eventHelper";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQrcode } from "@fortawesome/free-solid-svg-icons";
import { translateYMinus100 } from "../../../utils/animations/animations";
import { getImgUrl } from "../../../utils/contexts/apiContext";
const api_url = getImgUrl();

export default function ParticipatingsEvents({ events }) {
  const topAnimation = translateYMinus100();

  return (
    <animated.div style={topAnimation} className="participant_events_section">
      <h2 className="text_gradient">Vos billets :</h2>
      <ul>
        {events.map((event) => (
          <li key={event._id}>
            <div className="event_card">
              <img
                src={`${api_url}/uploads/events/${event.event_picture}`}
                alt="event thumbnail"
              />
              <p>{event.event_title}</p>
              <p>{displayEventDate(event.event_date)}</p>
              <Link to={"/eventstudio/" + event._id}>
                <FontAwesomeIcon icon={faQrcode} />
              </Link>
            </div>
          </li>
        ))}
      </ul>
    </animated.div>
  );
}
