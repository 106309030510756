import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { animated } from "react-spring";
import {
  translateX10,
  translateXMinus10,
} from "../../../utils/animations/animations";
import { getImgUrl } from "../../../utils/contexts/apiContext";
import Loader from "../../../components/Common/Loader/Loader";
import { formatEventTitle } from "../../../utils/helpers/eventHelper";
import { getUserByUserId } from "../../../utils/fetchs/userFetchs";
const api_url = getImgUrl();

export function UserMessage({ user, message }) {
  return (
    <>
      <animated.div style={translateX10()} className="message mymessage">
        <p>
          {message.content}
          <span>{dayjs(message.timestamp).format("HH:mm")}</span>
        </p>
        <img
          src={`${api_url}/uploads/profilepics/${user.profilepic}`}
          alt="profile"
        />
      </animated.div>
    </>
  );
}

export function SenderMessage({ sndr, message }) {
  return (
    <>
      <animated.div style={translateXMinus10()} className="message sndrmessage">
        <img
          src={`${api_url}/uploads/profilepics/${sndr.profilepic}`}
          alt="sender profile"
        />
        <p>
          {message.content}
          <span>{dayjs(message.timestamp).format("HH:mm")}</span>
        </p>
      </animated.div>
    </>
  );
}

export function ShareMessage({ msg, event }) {
  if (!event) {
    return <Loader />;
  }
  const event_title = formatEventTitle(event);
  return (
    <>
      <animated.div
        style={translateXMinus10()}
        className="message sndrmessage sharemessage"
      >
        <Link className="sharemessage_container" to={`/event/${event._id}`}>
          <div className="sharemessage_photo">
            <img
              src={`${api_url}/uploads/events/${event.event_picture}`}
              alt="current_event_image"
            />
            <p>
              <span>{dayjs(msg.timestamp).format("HH:mm")}</span>{" "}
            </p>
          </div>
          <div className="sharemessage_event_info">
            <h5>{event.event_title}</h5>
            <div className="sharemessage_event_description">
              <p>{event.description}</p>
            </div>
            <p>
              {dayjs(event.event_date).format("DD/MM/YYYY")} <br />
              {event.event_location_formatted}
            </p>
          </div>
        </Link>
      </animated.div>
    </>
  );
}
