import React, { useState, useEffect } from "react";
import { useSpring, animated } from "react-spring";
import { PublicationRepostPictureEvent } from "./Publications/PublicationRepostPictureEvent";
import { PublicationRepostEvent } from "./Publications/PublicationRepostEvent";
import { PublicationEventRelease } from "./Publications/PublicationEventRelease";
import { getAllPublications } from "../../../utils/fetchs/publicationFetchs";
import { useUser } from "../../../utils/contexts/userContext";
import Loader from "../../../components/Common/Loader/Loader";
import updateHead from "../../../utils/helpers/updatePageHead";

export default function Feed({ publications }) {
  const cssPath = "/assets/css/components/feed.css";
  const { user } = useUser();

  const bounceInAnimation = useSpring({
    from: { opacity: 0, transform: "translateX(-100%)" },
    to: { opacity: 1, transform: "translateX(0%)" },
  });

  function choosePublication(publication) {
    switch (publication.type) {
      case "event_release":
        return (
          <PublicationEventRelease
            key={publication._id}
            publication={publication}
            loggedInUser={user}
          />
        );
      case "repost_picture_event":
        return (
          <PublicationRepostPictureEvent
            key={publication._id}
            publication={publication}
            loggedInUser={user}
          />
        );
      case "repost_event":
        return (
          <PublicationRepostEvent
            key={publication._id}
            publication={publication}
            loggedInUser={user}
          />
        );
      default:
        return "";
    }
  }

  if (!publications) {
    return <Loader />;
  }

  return (
    <>
      {updateHead({ cssPath })}
      <animated.div style={bounceInAnimation}>
        <ul id="feed">
          {publications.map((publi) => choosePublication(publi))}
        </ul>
      </animated.div>
    </>
  );
}
