import { getApiUrl } from "../contexts/apiContext";

const api_url = getApiUrl();

export async function createFollowRequest(user, target_id) {
  try {
    const token = await user.token;
    const response = await fetch(
      `${api_url}/follow/create/${user._id}/${target_id}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    switch (response.status) {
      case 200:
        return await response.json();
      case 401:
        window.location = "/login-register?tk=true";
        break;
      default:
        throw new Error("Unable to create follow request");
    }
  } catch (error) {
    console.error(error.message);
  }
}

export async function unfollow(user, target_id) {
  try {
    const token = await user.token;
    const response = await fetch(
      `${api_url}/follow/unfollow/${user._id}/${target_id}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    switch (response.status) {
      case 200:
        return await response.json();
      default:
        throw new Error("Unable to unfollow");
    }
  } catch (error) {
    console.error(error.message);
  }
}

export async function acceptFollowRequest(user, request_id) {
  try {
    const token = await user.token;
    const response = await fetch(`${api_url}/follow/accept/${request_id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    switch (response.status) {
      case 200:
        return await response.json();
      default:
        throw new Error("Unable to accept follow request");
    }
  } catch (error) {
    console.error(error.message);
  }
}

export async function declineFollowRequest(user, request_id) {
  try {
    const token = await user.token;
    const response = await fetch(`${api_url}/follow/decline/${request_id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    switch (response.status) {
      case 200:
        return await response.json();
      default:
        throw new Error("Unable to decline follow request");
    }
  } catch (error) {
    console.error(error.message);
  }
}

export async function getFollowers(user, user_id) {
  try {
    const token = await user.token;
    const response = await fetch(`${api_url}/follow/followers/${user_id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    switch (response.status) {
      case 200:
        return await response.json();
      default:
        throw new Error("Unable to get followers");
    }
  } catch (error) {
    console.error(error.message);
  }
}

export async function getFollowings(user, user_id) {
  try {
    const token = await user.token;
    const response = await fetch(`${api_url}/follow/followings/${user_id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    switch (response.status) {
      case 200:
        return await response.json();
      default:
        throw new Error("Unable to get followings");
    }
  } catch (error) {
    console.error(error.message);
  }
}
