import React, { useState, useRef, useEffect } from "react";
import updatePageHead from "../../../../utils/helpers/updatePageHead";
import { translateXMinus100 } from "../../../../utils/animations/animations";
import { animated } from "react-spring";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faCalendarPlus,
  faMinus,
  faTrash,
  faInfoCircle, // Ajouter l'icône d'information
} from "@fortawesome/free-solid-svg-icons";
import { useUser } from "../../../../utils/contexts/userContext";
import { useNavigate } from "react-router-dom";
import { retreiveLocation } from "../../../../utils/helpers/retreiveLocation";
import { createEvent, updateEvent } from "../../../../utils/fetchs/eventFetchs";
import { uploadEventPicture } from "../../../../utils/fetchs/imageFetch";
import GradientButton from "../../../Common/Buttons/GradientButton";
import MainEventInfos from "./MainEventInfos";
import DetailedEventInfos from "./DetailedEventInfos";
import Loader from "../../../Common/Loader/Loader";
import SearchInput from "../../../Common/Navbar/tmpl/SearchInput";
import { UserCard } from "../../User/UserCard";
import Switch from "../../../Common/Buttons/Switch";
import { isMobile } from "react-device-detect";

export default function CreaEventCard({ event }) {
  const [showDetails, setShowDetails] = useState(false);
  const [eventConversation, setEventConversation] = useState(
    event?.event_conversation || true
  );
  const [programEventPubli, setProgramEventPubli] = useState(
    !!event?.program_publi_date
  );
  const [adultsOnly, setAdultsOnly] = useState(event?.adults_only || false);
  const [showEndDate, setShowEndDate] = useState(!!event?.event_end_date);
  const [privateEvent, setPrivateEvent] = useState(
    event?.private_event || true
  );
  const navigate = useNavigate();
  const { user } = useUser();
  const form = useRef();
  const [isPhone, setisPhone] = useState(
    isMobile || window.innerWidth < 767.98
  );

  /*Handle window reframing */
  useEffect(() => {
    const handleResize = () => {
      setisPhone(isMobile || window.innerWidth < 767.98);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Initialisation de tous les champs de eventData avec les données de l'événement existant ou les valeurs par défaut
  const [eventData, setEventData] = useState({
    event_title: event?.event_title || "",
    organiser_id: event?.organiser_id || user?._id || "", // Utilisateur connecté
    creation_date: event?.creation_date || null,
    event_picture: event?.event_picture || "",
    event_date: event?.event_date || "",
    program_publi_date: event?.program_publi_date || null,
    event_conversation: event?.event_conversation || true,
    event_end_date: event?.event_end_date || "",
    event_location_formatted: event?.event_location_formatted || "",
    event_location_coordinates: event?.event_location_coordinates || [0, 0],
    description: event?.description || "",
    guest_list: event?.guest_list || [],
    isCreating: !event, // Savoir si on est en mode création ou modification
    adults_only: event?.adults_only || false,
    private_event: event?.private_event || true,
  });

  const cssPath = "/assets/css/components/crea_event_card.css";

  const handleSubmit = async (e) => {
    e.preventDefault();

    const updatedEventData = { ...eventData };
    updatedEventData.event_title = form.current["event_title"].value;
    updatedEventData.event_date = form.current["event_date"].value;
    updatedEventData.event_end_date = showEndDate
      ? form.current["event_end_date"].value
      : "";
    updatedEventData.description = showDetails
      ? form.current["description"].value
      : "";

    // Gérer l'image si c'est un fichier
    if (eventData.event_picture instanceof File) {
      updatedEventData.event_picture = await uploadEventPicture(
        eventData.event_picture
      );
    }

    console.log(form.current["event_location"].value);

    // Récupérer la localisation
    const { loc, lat, lng } = await retreiveLocation(
      form.current["event_location"].value
    );
    updatedEventData.event_location_formatted = loc;
    updatedEventData.event_location_coordinates = [lat, lng];

    // Si la publication est programmée, récupérer la date
    if (programEventPubli) {
      updatedEventData.program_publi_date =
        form.current["program_publi_date"].value;
    }

    updatedEventData.adults_only = adultsOnly;
    updatedEventData.private_event = privateEvent;

    setEventData(updatedEventData);

    // Si l'événement existe déjà, on le met à jour, sinon on le crée
    if (event) {
      await updateEvent(event._id, updatedEventData, user);
    } else {
      await createEvent(updatedEventData, user);
    }

    navigate(`/events`);
  };

  const handleAddGuest = (guest) => {
    setEventData((prevData) => ({
      ...prevData,
      guest_list: [...prevData.guest_list, guest],
    }));
  };

  const handleRemoveGuest = (guestId) => {
    setEventData((prevData) => ({
      ...prevData,
      guest_list: prevData.guest_list.filter((guest) => guest._id !== guestId),
    }));
  };

  if (!eventData) {
    return <Loader />;
  }

  return (
    <>
      {updatePageHead({ cssPath })}
      <animated.div style={translateXMinus100()} className="event_card">
        <h2 className="text_gradient">
          {event ? "Modifier" : "Créer"} votre évènement
        </h2>
        <form onSubmit={(e) => handleSubmit(e)} ref={form}>
          <MainEventInfos
            eventData={eventData}
            setShowEndDate={setShowEndDate}
            showEndDate={showEndDate}
          />
          {showDetails && (
            <DetailedEventInfos
              eventData={eventData}
              setEventData={setEventData}
              privateEvent={privateEvent}
              setPrivateEvent={setPrivateEvent}
              eventConversation={eventConversation}
              setEventConversation={setEventConversation}
            />
          )}
          {showDetails && privateEvent && (
            <>
              <div className="event_invite">
                <p>Inviter des amis :</p>
                <SearchInput
                  eventData={eventData}
                  setEventData={handleAddGuest}
                  filter={"user"}
                  showGlass={false}
                  showFilter={false}
                />
              </div>
              {eventData.guest_list.filter((guest) => guest._id).length > 0 && (
                <p>Invités :</p>
              )}
              {eventData.guest_list.filter((guest) => guest._id).length > 0 &&
                eventData.guest_list
                  .filter((guest) => guest._id)
                  .map((guest) => (
                    <div className="guest_infos" key={guest._id}>
                      <div className="guest">
                        <UserCard user={guest} link={false} />
                      </div>
                      <FontAwesomeIcon
                        icon={faTrash}
                        className="icon"
                        onClick={() => handleRemoveGuest(guest._id)}
                      />
                    </div>
                  ))}
            </>
          )}
          {showDetails && (
            <>
              <div className="event_card_wrapper">
                <Switch
                  label="Programmer la publication :"
                  value={programEventPubli}
                  setValue={setProgramEventPubli}
                  tooltip="Si vous ne spécifiez aucune date, l'événement sera publié au moment de la création."
                />
                {programEventPubli && isPhone && (
                  <div className="event_card_input_wrapper">
                    <p className="date_txt">
                      Date et heure de la publication de l'événement :
                    </p>
                    <input
                      type="datetime-local"
                      name="program_publi_date"
                      className="event_date"
                      defaultValue={eventData.program_publi_date}
                    />
                  </div>
                )}
                <Switch
                  label="Réservé aux majeurs :"
                  value={adultsOnly}
                  setValue={setAdultsOnly}
                  tooltip="Les événements réservés aux majeurs nécessitent aux utilisateurs de vérifier leur profil Wive pour pouvoir participer."
                />
              </div>
              {programEventPubli && !isPhone && (
                <div className="event_card_input_wrapper">
                  <p className="date_txt">
                    Date et heure de la publication de l'événement :
                  </p>
                  <input
                    type="datetime-local"
                    name="program_publi_date"
                    className="event_date"
                    defaultValue={eventData.program_publi_date}
                  />
                </div>
              )}
            </>
          )}
          <div className="event_card_wrapper">
            <button
              type="button"
              className="details_btn"
              onClick={() => setShowDetails(!showDetails)}
            >
              <FontAwesomeIcon
                icon={showDetails ? faMinus : faPlus}
                className="icon"
              />
              {showDetails ? "Moins de détails" : "Plus de détails"}
            </button>
            <GradientButton
              icon={faCalendarPlus}
              text={"Créer votre évent"}
              padding={"0px 30px"}
            />
          </div>
        </form>
      </animated.div>
    </>
  );
}
